<!-- eslint-disable no-irregular-whitespace -->
<template>
  <b-modal
    id="termsModal"
    ref="termsModal"
    title="Terms Of Service"
    size="xl"
    hide-footer
  >

    <div class="WordSection1">

      <h1
        align="center"
        style="margin-top:0in;text-align:center;line-height:normal;
page-break-after:auto"
      ><a name="_6i8noqfg1b99" /><b><span
        lang="EN"
        style="font-size:23.0pt"
      >Fastinvoice Terms of Service</span></b></h1>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;text-align:justify"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#212529"
      >Effective as of: January 1, 2023</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;text-align:justify"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%"
      ><br>
        Important: By using, downloading or installing any element of the Product (as
        defined in Section 1 below), you indicate that you have read, understood, and
        agree to be bound by these Fastinvoice Terms of Service (“Terms”). These Terms
        govern your use of the Product and form a legal contract (“Agreement”) between
        Fastinvoice, LLC, including its corporate affiliates in your local jurisdiction
        as applicable, such as Cimrid Pty Ltd (“Fastinvoice”, “we” or “us”),  and you
        (“Customer” or “you”). </span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >By electronically accepting these
        Terms or by establishing an Fastinvoice account or using the Product after the
        Effective Date of these Terms, you consent and agree to comply with these
        Terms.  This Agreement includes and you are consenting to:</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice’s
        Privacy Notice</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice’s
        Billing Policy</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice
        Electronic Disclosure and Consent Policy</span></p>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Payment
        network rules, as applicable</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >When you agree to these Terms and
        accept this Agreement, you do so on behalf of yourself and any person that you
        entitle or authorize to use your Fastinvoice account (with you, each a “User”
        of your Fastinvoice account). </span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >If you are an individual using,
        downloading or installing any element of the Product on behalf of a another,
        whether a natural person (“Individual”) or legal non-natural person or entity
        (an “Organization”), then you are binding the Individual or Organization to
        these Terms and represent and warrant that you have full power and authority to
        do so. </span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice may modify these Terms
        from time to time as described in Section 22 (Modifications to Terms) below.</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >THESE TERMS CONTAIN A MANDATORY
        INDIVIDUAL ARBITRATION REQUIREMENT, A CLASS ACTION WAIVER, AND A JURY TRIAL
        WAIVER THAT REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE
        DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS. DETAILS APPEAR BELOW IN
        SECTION 23 (Governing Law, Arbitration, and Class Action/Jury Trial Waiver).</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_r22ncq8bqt1w"
      /><b><span
        lang="EN"
        style="color:black"
      >1. Introduction.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Thank you for using the Fastinvoice
        Product. The “Product” means (i) our suite of services and features that helps
        you run your small business, manage customer relationships, send invoices,
        accept payments, improve cash flow, create an online presence, and more, (ii)
        our related mobile applications and software (“Software”), (iii) any Updates
        (defined below) and (iv) Fastinvoice’s standard user documentation.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_og5p8khzepbp"
      /><b><span
        lang="EN"
        style="color:black"
      >2. Your Account.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >You must be at least 18 years of age
        to use the Product. Use of the Product may require you to create an account and
        register your email address and a password (“Login Credentials”). To register
        an Fastinvoice account and/or enroll as a User, you will be required to provide
        us with information about yourself, including but not limited to your name,
        address, email address and other contact or identifying information, and about
        your Organization, as appropriate.  You agree that the information you provide
        to us will be accurate and you will keep it up-to-date at all times. </span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_f84uyjlcn4re"
      /><b><span
        lang="EN"
        style="color:black"
      >3. Electronic
        Communications.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >You consent to receive notifications
        from Fastinvoice electronically and agree that all notices, disclosures, and
        other communications that we provide to you electronically satisfy any legal
        requirement that such communications be in writing or be delivered in a
        particular manner. You agree that you have the ability to store such electronic
        communications such that they remain accessible to it in an unchanged form. You
        agree to keep your contact information current. For more information, please
        review Fastinvoice’s Electronic Disclosure and Consent Policy <br>
      </span><b><span
        lang="EN"
        style="color:black"
      >4. Security of User Login
        Credentials.</span></b></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >  Users are solely responsible for
        maintaining the confidentiality of Login Credentials.  To the extent allowed by
        law, you are solely responsible for any and all actions taken using your
        account, including all instructions, including but not limited to any payment instructions,
        electronic transfer instructions, or deposit instructions provided to
        Fastinvoice, our service providers, or Payment Providers, whether authorized or
        not.   Each User agrees to keep User Login Credentials confidential and secure,
        and Users will not give or make Login Credentials available, directly or
        indirectly, to any unauthorized individual.  You acknowledge and agree that if
        Users allow a person to gain access to Login Credentials, with or without
        permission, the User is authorizing that person to use the Product, and you
        will be responsible for all transactions, instructions, and actions that result
        from such access, even if they are unauthorized.   </span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >You acknowledge and agree that our
        security procedures are a commercially reasonable method of verifying your
        identity and instructions, providing security against unauthorized
        transactions, unauthorized actions, and fraud, and protecting your Fastinvoice
        account.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_4w4xmcewex7u"
      /><b><span
        lang="EN"
        style="color:black"
      >5. Purpose of Use
        of the Product.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >The Product is designed to be used
        for business purposes.  You represent and warrant that you are not using the
        Product for primarily personal, family, or household purposes.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_mg9hxowvqars"
      /><b><span
        lang="EN"
        style="color:black"
      >6. Third-Party
        Service Providers.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >You grant to Fastinvoice and we
        reserve the right to use third parties to provide or facilitate the Product as
        described in Section 12e. of this Agreement.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_u5u43eymqthh"
      /><b><span
        lang="EN"
        style="color:black"
      >7. The Product.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-left:.25in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Use of the Product. Subject
        to these Terms, including Section 8 (Usage Plans, Fees and Beta Releases)
        below, you may access and use the Product during the applicable Subscription
        Term for your internal business purposes and for the benefit of your customers,
        business partners or other applicable individuals (“End Customers”), in accordance
        with our standard user documentation. This includes the right to download and
        use the Software on compatible devices that you own or control, to use the
        Product to send to End Customers bona fide invoices, estimates and quotes
        (“Invoices”), to create and display a website for your business, and to manage
        data regarding your End Customers, all as permitted under this Agreement and
        your Usage Plan. You may permit your employees and agents to use the Product on
        your behalf, but you agree that you remain responsible for these Users’ acts,
        omissions, and compliance with these Terms.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.25in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Restrictions. Your rights to
        use the Product are non-exclusive, non-transferable and non-sublicensable. You
        will not (or permit anyone else to): (i) rent, lease, reproduce, modify, create
        derivative works of, distribute or transfer the Product; (ii) use the Product
        for the benefit of any third party or incorporate the Product into any other
        product or service; (iii) circumvent mechanisms in the Product intended to
        limit your use; (iv) reverse engineer, disassemble, decompile, or translate the
        Product or attempt to derive the source code of the Software or any non-public
        APIs; (v) publicly disseminate information regarding the performance of the
        Product; (vi) access the Product to build a competitive product or service;
        (vii) submit any viruses, worms or other harmful code to the Product or
        otherwise interfere with or cause harm to the Product; (viii) seek to access
        information or data of other Fastinvoice customers; (ix) use the Product to
        transmit spam or other unsolicited email; or (x) use the Product except as
        expressly permitted herein.</span></p>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.25in;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Reviews.
        The reviews feature of the Product allows End Customers and other third parties
        to leave reviews and ratings of the Customer.  While Fastinvoice may allow such
        third parties the opportunity to revise their reviews and ratings, Fastinvoice
        itself has no obligation to remove or revise a review or rating or to allow the
        Customer to do so. </span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_4cl8yvovujd"
      /><b><span
        lang="EN"
        style="color:black"
      >8. Usage Plans,
        Fees and Beta Releases.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-left:.25in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Usage Plans. Fastinvoice
        offers various usage plans for the Product, as described at  <span
          style="background:yellow"
        >https://fastinvoice.me/pricing.</span> Your permitted
        scope of use – such as features available and number of Invoices and users –
        depends on the usage plan you select. Your specific plan will be identified in
        the Order Terms (as defined below).</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.25in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Order Terms. The set term of
        your subscription to the Product (“Subscription Term”), usage plan and other
        order details will be identified in the terms presented when you register,
        order or pay for the Product (“Order Terms”) and as further described in our
        Billing Policy. These Terms incorporate the Order Terms and any URLs and
        Fastinvoice policies they reference, including the Billing Policies. Depending
        on how you use the Product, the Order Terms may be presented by Fastinvoice
        (e.g., through our website), by a third-party application store or marketplace
        (“App Marketplace”), or through the Product itself.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.25in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Subscription Term. The
        Product is provided on a subscription basis for a set term designated in the
        Order Terms (“Subscription Term”). Unless renewed, the Product will revert to
        limited (or no) functionality upon expiration of a Subscription Term.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.25in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Renewals and Other Important
        Terms. Renewals, subscription upgrades and other important billing terms are
        governed by our Billing Policy.  Please review these terms carefully, including
        sections related to auto-renewal of your Subscription Term.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.25in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fees. You must pay all fees
        and taxes to Fastinvoice or the authorized third party (e.g., App Marketplace)
        through which you made your purchase, as specified in the Order Terms and
        further detailed in our Billing Policy. All fees are non-refundable except as
        expressly specified in Section 16b. (Termination or Suspension by Fastinvoice),
        Section 22 (Modifications to Terms), Section 25 (Apple-Related Terms), the
        Order Terms or our Billing Policy.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.25in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Free Trials. If specified in
        the Order Terms, Fastinvoice may offer you free access to the Product during a
        limited Subscription Term (“Free Trial”). Important: Depending on the Order
        Terms, at the end of your Free Trial, you will be asked to subscribe to one of
        the plans for the Product. Your usage plan and the fees for the Subscription
        Term may be specified in the Order Terms when you begin the Free Trial or may
        be presented when the Free Trial ends. See the Billing Policy and Order Terms
        for further details.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.25in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Beta Releases. As part of
        Fastinvoice’s effort to expand the functionality of the Product, certain
        features of the Product may be made available to customers on a “beta” basis
        (“Beta Releases”). If Customer chooses to use a Beta Release, Customer
        acknowledges that the Beta Release may not be complete or fully functional and
        may contain bugs, errors, omissions and other problems. Fastinvoice does not
        guarantee that future versions of a Beta Release will be made available under
        these Terms or otherwise.</span></p>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.25in;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice
        Money. If you register for or use Fastinvoice Money, or connect your instance
        of the Product to a Third Parter Service (whether or not you receive payments
        from End Customers through the Product), the additional terms in Section 13
        apply.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_m2jo5owxtkm"
      /><b><span
        lang="EN"
        style="color:black"
      >9. Support and
        Updates.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice uses reasonable efforts
        to provide email, live chat and online self-help. All support is governed by
        Fastinvoice then-current support policies.   Fastinvoice reserves the right,
        from time to time to update the Product (“Updates”) and/or make new applications,
        tools, features, or functionalities available through the Product, the use of
        which may be contingent upon your agreement to the additional terms and and/or
        service fees.  All Updates are governed by these Terms unless we specify
        otherwise. In certain cases, we may require you to download Updates to continue
        to use the Product. Updates and other Product changes are made at our sole
        discretion; we have no liability to you for any such changes and do not
        guarantee that any changes will meet your needs.  Fastinvoice reserves the
        right to discontinue the Service, or certain applications, tools, features or
        functionalities of the Service, for any reason at any time, in Fastinvoice’s
        sole discretion, without Fastinvoice incurring any liability to you.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_xlzrinkv34kj"
      /><b><span
        lang="EN"
        style="color:black"
      >10. Transfer
        System Rules.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >You acknowledge that electronic
        transfers or deposits made through the Product will be governed by the rules of
        any funds transfer through which the transfers are made, as amended from time
        to time, including, without limitation, the National Automated Clearing House
        Association (“NACHA Rules”), and you agree to be bound by such rules. </span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_nkyth0fwo9dt"
      /><b><span
        lang="EN"
        style="color:black"
      >11. Customer
        Data.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-left:22.5pt;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >■<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice’s Access to
        Customer Data. If you, any User, or End Customer submits, uploads, stores,
        shares, or otherwise processes your documents, information, data ( “Customer
        Data”), you consent to Fastinvoice’s access and processing of your Customer
        Data in accordance with our Privacy Notice, this Agreement, the settings in
        your Fastinvoice account, or the features of the Product you elect to use.
        Fastinvoice reserves the right to remove or delete your Customer Data from the
        Product for any reason, including if your Customer Data violates this Agreement
        or applicable law, in Fastinvoice’s reasonable discretion, without incurring any
        liability to you.  Except as otherwise provided in this Agreement, we do not
        claim ownership of your Customer Data.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:22.5pt;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >■<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Your Rights. As between
        Fastinvoice and Customer, Customer retains all of its rights to any Customer
        Data that Customer submits to the Product (including through Third-Party
        Services) or that End Customers provide to Customer through the Product.   </span></p>

      <p
        class="MsoNormal"
        style="margin-left:22.5pt;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >■<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Use of Customer Data.
        Customer grants Fastinvoice a worldwide, non-exclusive, royalty-free right to
        use, copy, store, transmit, distribute, publicly perform and display, create
        derivative works of and modify any Customer Data in order to provide the
        Product to Customer and otherwise to operate, improve and maintain the Product.
        This includes Fastinvoice’s distribution of Invoices containing Customer Data
        to the End Customers designated by Customer through the Product. Fastinvoice
        may use, disclose and retain Customer Data collected for you from End Customers
        only to provide the Product to Customer, comply with legal requirements, and
        exercise its rights under this Agreement and applicable law.  Fastinvoice may
        use the services of subcontractors to provide the Product and permit them to
        exercise our rights to Customer Data.  </span></p>

      <p
        class="MsoNormal"
        style="margin-left:22.5pt;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >■<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Instant Websites. Customer
        Data submitted by the Customer may be used as part of the Instant Websites
        feature, which allows the Customer to create a website (the “Instant
        Website”).  While Customer retains its rights in the Customer Data, Customer
        receives no license to use the code underlying the Instant Website or to copy,
        export, publish or republish, create derivative works from, or otherwise
        exploit the Instant Website outside of the Product.  The Instant Website as a
        whole, and the components of it that are supplied by Fastinvoice or its
        suppliers, are not Customer Data, and such components and the Instant Website
        as a whole will not be available to the Customer after the Subscription Term.<br>
        Instant Websites allows you to use a domain that you have purchased from a
        third-party.  By using such a domain, you represent and warrant that you own
        the domain and that your use of the domain does not violate any third-party’s
        rights, including any intellectual property right, or rights of publicity,
        confidentiality, or other property or privacy right.  You may also use domains
        for your Instant Website that you purchase through Google.  You represent and
        warrant that your use of a domain purchased from Google for your Instant
        Website complies with this Agreement or any applicable terms provided by
        Google.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:22.5pt;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >■<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Storage of Customer Data.
        Fastinvoice does not provide an archiving service. Fastinvoice agrees only that
        it will not intentionally delete Customer Data prior to termination or
        expiration of your Subscription Term. We expressly disclaim all other storage
        obligations to the extent permitted by applicable law. You are solely
        responsible for maintaining backups of Customer Data on your own systems.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:22.5pt;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >■<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Aggregate/Anonymous Data. In
        addition to its other rights above, Fastinvoice may aggregate Customer Data
        with other data so that the results are non-personally identifiable to Customer
        and may collect or create anonymous or de identified technical and other data about
        Customer’s use of the Product (“Aggregated/Anonymous Data”). Fastinvoice owns
        all Aggregate/Anonymous Data and may use and disclose Aggregate/Anonymous Data
        for its marketing, reporting and other lawful business purposes.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:22.5pt;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >■<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Compelled Disclosures. Fastinvoice
        may share Customer Data when we have a good faith belief that access, use,
        preservation or disclosure of such information is reasonably necessary and
        appropriate to: (i) satisfy any applicable law, subpoena, legal process or
        governmental request; (ii) enforce these Terms, including investigation of
        potential violations hereof; or (iii) protect against imminent harm to the
        Product or the rights, property or safety of Fastinvoice, its users or the
        public as required or permitted by applicable law.</span></p>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:22.5pt;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >■<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Other
        Data Collection and Handling. You agree to Fastinvoice’s use, disclosure, and
        other handling of your account information (as described in Section 2),
        personal information in Customer Data and certain other information about your
        use of the Product in accordance with our Privacy Notice. </span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_aqjx4uhict96"
      /><b><span
        lang="EN"
        style="color:black"
      >12. Your
        Responsibilities</span></b><span
        lang="EN"
        style="color:black"
      >.  </span></h3>

      <p
        class="MsoNormal"
        style="margin-left:22.5pt;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Generally. Customer
        represents, warrants and agrees that: (i) Customer’s use of the Product will
        comply with all applicable local, state, federal and international laws and
        regulations, including those relating to privacy, data security, export control
        and electronic commutations (“Laws”); (ii) Customer has obtained all necessary
        rights, releases and permissions to provide and allow the processing, use and
        disclosure of Customer Data by Fastinvoice under these Terms; (iii) neither the
        Customer Data nor its processing, use, or disclosure will violate any Laws,
        third-party rights (including intellectual property rights, rights of privacy
        or rights of publicity) or any applicable Customer privacy policy; and (iv)
        Customer will not use the Product in support of, or to further, any activities
        prohibited by any Laws (e.g., money laundering) or, even if not prohibited by
        law, for gambling, prostitution, alcohol, drug, pharmaceutical or healthcare
        businesses or services.<br>
        Without limiting the foregoing, with respect to Product features that allow
        Customer to send electronic communications (“Third-Party Communications”),
        Customer represents, warrants and agrees that: (i) it will obtain the prior
        opt-in consent from each recipient of such communications where legally
        required, (ii) it will include an unsubscribe mechanism and will promptly honor
        such opt-out requests where legally required, and (iii) it will not use the
        Product for spam or any other abusive or annoying communication. You
        acknowledge that you are solely responsible for the content of such Third-Party
        Communications and agree to indemnify, defend, and hold us harmless from and
        against all claims, losses, expenses, damages, and costs (including attorney’s
        fees and costs) arising out of such Third-Party Communications.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:22.5pt;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Prohibited Uses. Except in
        the specific fields designated by Fastinvoice, Customer agrees not to submit to
        the Product any tax identification numbers, bank account numbers or credit or
        debit card numbers. In addition, Customer agrees not to include in any
        Invoices  or otherwise submit to the Product any of the following: (i) patient,
        medical or other protected health information (“ePHI”) regulated by any
        applicable laws, rules or regulations, including the Health Insurance
        Portability and Accountability Act (as amended and supplemented) (“HIPAA”)
        (including without limitation any health insurance information); (ii) other
        special categories of data enumerated in European Union Regulation 2016/679,
        Article 9(1) or any successor legislation, including personal data revealing
        racial or ethnic origin, political opinions, religious or philosophical
        beliefs, trade union membership, genetic data, biometric data, or data
        concerning an individual’s sex life or sexual orientation (iii) social security
        numbers or similar; (iii) driver’s license numbers or governmental
        identification card numbers; (iv) other information subject to regulation or
        protection under specific laws such as the Children’s Online Privacy Protection
        Act; or (v) any data similar to the above protected under foreign or domestic
        laws (collectively, “Prohibited Data”). Customer further agrees not to use the
        Product for activities where use or failure of the Product could lead to death,
        personal injury or environmental damage, including life support systems,
        emergency services, nuclear facilities, autonomous vehicles or air traffic
        control (“High Risk Activities”). Customer acknowledges that the Product is not
        intended to meet any legal obligations for Prohibited Data or High Risk
        Activities, including HIPAA requirements, and that Fastinvoice is not a
        Business Associate as defined under HIPAA. Notwithstanding anything else in
        these Terms, Customer acknowledges that Fastinvoice has no liability for
        Prohibited Data or use of the Product for High Risk Activities. Fastinvoice
        does not allow its services to be used to promote hate, violence, or other
        forms of intolerance that are discriminatory.  As applicable, you represent and
        warrant that you and/or your Organization are not a prohibited business under
        our Service Provider's </span><span lang="EN"><a
        href="https://stripe.com/en-ca/legal/restricted-businesses"
      ><span
        style="font-size:12.0pt;line-height:155%;color:#1155CC"
      >list of prohibited
        businesses</span></a></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:
155%"
      >.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:22.5pt;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >●<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Responsibility for Customer
        Data. Fastinvoice takes no responsibility and assumes no liability for any
        Customer Data, and Customer is solely responsible for Customer Data and the
        consequences of its use.</span></p>

      <ol
        style="margin-top:0in"
        start="1"
        type="1"
      >
        <li
          class="MsoNormal"
          style="line-height:155%"
        ><span
          lang="EN"
          style="font-size:
     12.0pt;line-height:155%"
        >Customer Indemnity. Customer will indemnify,
          defend (at Fastinvoice’s request) and hold harmless Fastinvoice and its affiliates
          including Cimrid Pty Ltd and their respective employees, agents, officers,
          directors and other customers (collectively, the “Fastinvoice Parties”)
          from and against any and all claims, costs, damages, losses, liabilities
          and expenses (including reasonable attorneys’ fees) arising out of or in
          connection with (i) any Customer Data, (ii) Customer’s negligence,
          misconduct or breach or alleged breach of these Terms, or (iii) any
          Customer business, service, product or offering used in connection with the
          Product and (iv) Customer’s relationships or disputes with any third
          parties in connection with such business, service, product or offering,
          including End Customers, Payment Partners, or Capital or Lending
          Partners.  Customers may not settle or compromise any matter subject to
          this paragraph without the prior written consent of Fastinvoice, which it
          may withhold in its sole discretion.  Fastinvoice, along with any affected
          Fastinvoice Party, may appoint their own counsel and participate in and
          control the defense, compromise and settlement of any such matter, even
          where Fastinvoice has invoked its right to defense under this paragraph.</span></li>
        <li
          class="MsoNormal"
          style="margin-bottom:12.0pt;line-height:155%"
        ><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%"
        >Third-Party Services.
          You may choose to use the Product and share Customer Data with Third-Party
          Services that are made available to you by Fastinvoice in connection with
          your use of the Product or for which Fastinvoice otherwise facilitates
          your use with the Product. Third-Party Services are not considered part of
          the Product. “Third-Party Service” means any platform, add-on, service or
          product that you elect to use, integrate or enable for use with or through
          the Product, including but not limited to accounting software, Payment
          Partners and Capital or Lending Partners (as defined below). Use of
          Third-Party Services is subject to your agreement with the relevant
          provider and not these Terms, except that these Terms require you to
          comply with your agreement with the relevant provider. Fastinvoice does
          not control and has no liability for Third-Party Services, including their
          security, functionality, operation, availability or interoperability or
          how the Third-Party Services or their providers use Customer Data. If you
          choose to enable a Third-Party Service with the Product, Fastinvoice may
          access and exchange Customer Data with the Third-Party Service, including
          as further described in these Terms (See Section 13 (Fastinvoice Money)
          and Section 14 (Third Party Capital and Lending Partners)).</span></li>
      </ol>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_4nlupjcdlw7j"
      /><b><span
        lang="EN"
        style="color:black"
      > 13. Fastinvoice
        Money, Banking, Debit Card, Bank Transfers, and Card Payments.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >1.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >General. Certain Third-Party
        Services that may be used with the Product to allow you to receive payments
        from your End Customers for Invoices you generate through the Product or to use
        other financial services (“Fastinvoice Money”). The payments are processed and
        financial services are provided through Payment Partners. If you use
        Fastinvoice Money, you will be required to provide certain underwriting and
        other information requested by the applicable Payment Partner, and Fastinvoice
        may share certain Customer Data and other information about you, your business,
        or your Customers with the Payment Partners and related integration partners,
        as described in our Privacy Notice, and Fastinvoice may further handle the
        information as Customer Data under these Terms and our Privacy Notice.  For
        clarity, your relationship with any Payment Partner (including their use of
        your information) is governed by your separate agreement or terms with the
        Payment Partner, not by these Terms. </span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >2.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Disbursement Account:  To
        receive disbursement of funds from your End Customers through Fastinvoice
        Money, you can associate a valid bank account with Fastinvoice Money
        (“Disbursement Account”).  When you enter Disbursement Account information for
        purposes of using Fastinvoice Money, you represent and warrant:</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >1.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >You have the authority to
        provide and disclose the Disbursement Account information to Fastinvoice.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >2.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >You have the authority to and
        do authorize the initiation of ACH debit or credit entries as applicable, to
        the Disbursement Account in accordance with any instructions you provide in
        connection with your use of Fastinvoice Money, and, as necessary, the
        initiation of adjustments or reversals as provided under this Agreement, or
        applicable law, rules, or regulations.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >3.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >You will provide complete and
        accurate information to Fastinvoice about your Disbursement Account, including,
        but not limited to, information on the ownership of the account, and you will
        notify us promptly if the Payment account information changes.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >4.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >You agree that your
        Disbursement Account will be enabled for ACH debits, and you agree to reimburse
        us for all penalties and fees resulting from the rejection of an ACH debit or
        credit to your Disbursement Account due to your Disbursement Account not being
        enabled for ACH debits.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >3.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > Your Responsibilities. Any
        purchase or payment is solely between you and the End Customer; the Fastinvoice
        Parties are not party to your transactions and assume no liability or
        responsibility for your products, services or offerings. The Fastinvoice
        Parties are not your bank, agent, or trustee or otherwise involved in the flow
        of funds or payments. Any payment dispute must be resolved between you and the
        End Customer.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >4.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >To establish an account with
        the Bank to enable banking services, you will need to enter into certain
        additional agreements with the Bank (“</span><span
        lang="EN"
        style="font-size:
12.0pt;line-height:155%;font-family:Roboto"
      >Banking Agreements</span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >”).  These Banking Agreements
        will be provided to you by the Bank should you choose to open a Bank Account.
        The Banking Agreements will be between you and the Bank. In the event of any
        inconsistency between these Terms and the Banking Agreements, these Terms will
        govern the relationship between you and Fastinvoice, and the Banking Agreements
        will govern the relationship between you and the Bank.<br>
        If you establish a Bank Account under the Bank’s Business Deposit Account
        Agreement, Fastinvoice will be responsible for the following, which are subject
        to these Terms and Fastinvoice’s </span><span
        lang="EN"
        style="font-size:12.0pt;
line-height:155%;font-family:Roboto"
      >Privacy Notice</span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >: (i) providing the interface for the
        Bank Account and related functionality within the Fastinvoice  app and website,
        subject to the proper functioning of the Bank’s interconnected systems, (ii)
        placing certain disclaimers and disclosures related to the Bank Account and
        Bank Account-powered transactions in the Fastinvoice app and website, (iii)
        administering your login access to the Bank Account through the Fastinvoice app
        and website, and (iv) providing first-line customer support for certain
        matters, subject to the Bank’s cooperation and authority to override
        Fastinvoice’s support decisions. </span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >5.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice Money Card
        Payments</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >1.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:Roboto"
      >General:</span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > Fastinvoice Money provides
        features and services that allow you to accept card payments from your End
        Customers (“Card Payments”). Card Payments are processed through the
        Third-Party Services and certain Payment Partners. To enable Card Payment
        acceptance, you will need to accept certain agreements with the Card Payment
        Provider for each type of Card Payment method that you enable (“Card Payment
        Agreements”).  These Card Payment Agreements will be provided to you by the
        specific Card Payment Provider.  In the event of any inconsistency between
        these Terms and the Card Payment Agreements, these Terms will govern the
        relationship between you and Fastinvoice, and the Card Payment Agreements will
        govern the relationship between you and the Card Payment Provider(s).</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >2.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Customized Payment Transfer
        Limits: Funds from processed Card Payments will be transferred to your
        Disbursement Account on a daily basis.  When you sign-up for Fastinvoice Money
        Card Payments, you will be assigned customized payout limits. The assigned
        limits determine your daily and monthly Fastinvoice Money Card Payment transfer
        limits will apply to your Fastinvoice Money Card Payments Disbursement
        Account.  Your assigned limits will affect the total amount of Card Payment
        funds that can be transferred to your Disbursement Account on a daily and
        monthly basis. Your assignment limits are based on, among other things, various
        risk-based factors related to your business, your Fastinvoice account, and
        transaction history.   To view your daily and monthly transfer limits in the
        app, go to the Fastinvoice Money Card Transactions  section in your account and
        click on Limits &amp; Support. You may also request a transfer limit increase
        if eligible.  We may also reevaluate your account from time to time, which may
        result in your account being assigned to a new limit.  <br>
        Any amount that exceeds either the daily or monthly limit will be transferred
        to your Disbursement Account the next business day or next month, depending on
        which limit is exceeded. </span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >3.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Your disbursement account:
        Fastinvoice Money Card Payments will be turned on as soon as you are approved.
        At the time of set-up, if you decide to skip linking your disbursement account,
        then Fastinvoice will have no way to payout these funds to you. If, after a
        reasonable amount of time, you still do not provide a disbursement account,
        Fastinvoice/our payment provider will refund any card payment funds that you
        collected, back to the payer.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >4.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:Roboto"
      >Chargebacks
        and Refunds:  </span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >A
        (“</span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:
Roboto"
      >Chargeback</span><span
        lang="EN"
        style="font-size:12.0pt;line-height:
155%"
      >”) is a  Card Payment that is successfully charged back by request of
        your End Customer or the issuer of the End Customer’s card resulting in the
        cancellation of a Card Payment for which you have been paid or are due to be
        paid.  If a Chargeback occurs for a Card Payment for which funds (“</span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:Roboto"
      >Card
        Payment Funds</span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >”)
        have already settled and been disbursed to you, the Chargeback results in your
        unconditional obligation to return the settled Card Payment Funds to
        Fastinvoice.<br>
        Your receipt of a settlement and/or authorization for a Card Payment does not
        unconditionally entitle you to receive or keep the Card Payment Funds.  If an
        End Customer successfully claims a Chargeback in accordance with relevant
        network and issuer rules, you lose entitlement to the relevant amount and must
        return that amount to Fastinvoice or its authorized Card Payment Providers.
        Fastinvoice or our authorized Card Payment Providers may withhold the amount of
        the Chargeback from the Card Payment Funds from subsequent Card Payments made
        to you through Fastinvoice Money.  Card Payments are credited to your
        Disbursement Account on the condition that no Chargeback shall occur.<br>
        For every Chargeback where Fastinvoice is obliged to pay the Chargeback amount,
        you give Fastinvoice authority to deduct the same amount from your Disbursement
        Account.  If there are insufficient funds in your Disbursement Account to cover
        the debit for the Chargeback, this will result in a (“</span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:Roboto"
      >Negative Balance</span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >”).  A Negative Balance
        represents an amount that you owe to us, and, in this situation, you must
        immediately add funds to your Disbursement Account to resolve the Negative
        Balance.  If you don’t immediately add funds to cover your Negative Balance,
        Fastinvoice may: (1) Engage in collection efforts to recover the amount due
        from you to Fastinvoice;  (2) place a limitation on your Fastinvoice Account;
        or (3) take other action on your Fastinvoice account as provided in this
        Agreement.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >5.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:Roboto"
      >Chargeback
        Fees</span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >: For every
        Chargeback, you will be charged a non-refundable fee.  Card Payment Fees
        charged for executing the original Card Payment will not be refunded in part or
        in whole to you if the Card Payment is made subject to a Chargeback or refund.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >6.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:Roboto"
      >Card
        Payment Fees: </span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice
        charges you Card Payment Fees for each Card Payment you accept through
        Fastinvoice Money as described in Fastinvoice’s </span><span lang="EN"><a
        href="https://support.2go.com/hc/en-us/articles/4405188294925-Are-there-fees-for-accepting-Invoice2go-Money-card-payments-"
      ><span
        style="font-size:12.0pt;line-height:155%;font-family:Roboto;color:#324BFF;
text-decoration:none"
      >here</span></a></span><span
        lang="EN"
        style="font-size:
12.0pt;line-height:155%"
      >.  As a service to our Customers, we offer a feature
        that allows you to add Card Payment Fees to the invoices that you send to your
        End Customers.  If you elect to turn on this feature, you must first accept the
        associated terms, which will be presented to you within the Product.  By
        accepting those terms and turning on this feature, you will be able to include
        Card Payment Fees on the invoices that you send to your End Customers through
        the Product.  The laws in certain U.S. states may make it illegal to pass these
        Card Payment Fees on to your End Customers.  Depending on the state where you
        or your End Customers are located, passing these Card Payment Fees to your
        customers may violate certain laws.  You are solely and completely responsible
        for complying with any and all laws related to passing these Card Payment Fees
        to your customers.  By allowing you to turn on this feature, Fastinvoice is not
        making any determination, recommendation, or judgment whatsoever about whether
        it is legal for you to pass Card Payment Fees to your customers.  Fastinvoice
        will not be liable or responsible in any way for your compliance with
        applicable laws, and you will be solely liable for any and all penalties, fees,
        or other consequences of violating any applicable laws by turning on this
        feature.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >6.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > Disputes. WITHOUT LIMITING
        SECTION 17 (DISCLAIMER OF WARRANTIES) OR SECTION 18 (LIMITATION OF LIABILITIES)
        BELOW, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE Fastinvoice
        PARTIES MAKE NO WARRANTY AND ASSUME NO RESPONSIBILITY OR LIABILITY FOR
        Fastinvoice MONEY OR ANY DISPUTES, CHARGEBACKS, OVERCHARGES, DELAYED PAYMENTS,
        INSUFFICIENT FUNDS, EXPIRED CARDS, CIRCUMSTANCES BEYOND OUR CONTROL (INCLUDING
        WITHOUT LIMITATION POWER OUTAGES, SERVICE INTERRUPTIONS OR OTHERWISE), OR OTHER
        ISSUES RELATING TO TRANSACTIONS MADE THROUGH Fastinvoice MONEY OR OTHER
        SERVICES OFFERED BY THE RELEVANT THIRD-PARTY SERVICES.<br>
        Disputes can arise from both authorized and unauthorized transactions.
        Fastinvoice is not responsible for or liable to you for authorized and
        completed charges or payments that are later the subject of a Chargeback,
        dispute, refund, or reversal, are submitted without authorization or in error,
        or violate any laws<br>
        You are immediately responsible to us for all Chargebacks, disputes, refunds,
        reversals, or returns regardless of the reason, timing, or method of payment.
        In many but not all cases, you may have the ability to challenge a Chargeback
        or dispute by submitting evidence to us.  We may request additional information
        to provide to Third-Party Services to assist you in contesting the Chargeback
        or dispute, but we cannot guarantee that your challenge will be successful. You
        agree to provide any documents that Fastinvoice requests regarding challenging
        a Chargeback within the timeframe requested by Fastinvoice. Third-Party Services
        may deny your challenge for any reason they deem appropriate. Where a challenge
        is entirely or partially successful, a Third-Party Service may credit funds
        associated with the Charge that is the subject of the Dispute (or a portion
        thereof) to your appropriate account.</span></p>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >7.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Termination
        of Fastinvoice Money Notwithstanding anything in these Terms to the contrary,
        Fastinvoice may modify, suspend or terminate Fastinvoice Money or any portion
        of it at any time, in its sole discretion, without notice or liability to
        you.     </span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_jbdzzgle39lb"
      /><b><span
        lang="EN"
        style="color:black"
      >14. Third Party
        Capital and Lending Partners.  </span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice has relationships with
        certain Third-Party Services (“Capital or Lending Partners”) that may provide
        Customers (and their End Customers, as permitted) with offers for working capital,
        loans, lines of credit, merchant cash advances, invoice factoring or the like
        (“Capital or Loans”). These offers may be presented through the Product or
        separately, and unless you opt-out, Fastinvoice may share certain information
        about you with the Capital or Lending Partners, as described in our </span><span
        lang="EN"
      ><a href="https://invoice.2go.com/privacyinline/"><span
        style="font-size:12.0pt;line-height:155%;color:#324BFF;text-decoration:none"
      >Privacy
        Notice</span></a></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >.
        Any Capital or Loans will be provided by the Capital or Lending Partners,
        subject to a separate agreement or terms between you and them. Fastinvoice is
        not a lender or broker and does not provide Capital or Loans or make credit
        decisions. WITHOUT LIMITING SECTION 17 (DISCLAIMER OF WARRANTIES) OR SECTION 18
        (LIMITATION OF LIABILITIES) BELOW, Fastinvoice HAS NO LIABILITY FOR THE CAPITAL
        OR LENDING PARTNERS, THEIR ACTS OR OMISSIONS, OR ANY CAPITAL OR LOANS (OR
        CREDIT DECISIONS) OF THE CAPITAL OR LENDING PARTNERS.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_v14qzfrpgjil"
      /><b><span
        lang="EN"
        style="color:black"
      /></b></h3>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_5tsamdx8xvb1"
      /><b><span
        lang="EN"
        style="color:black"
      >15. Ownership.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice and its licensors retain
        all right, title, and interest (including all intellectual property rights) in
        and to the Product and any and all related and underlying technology and
        documentation. Customer is obtaining only a limited usage right to the Product,
        regardless of use of words like “purchase”, “sale” or similar terms. If
        Customer submits any comments, suggestions or other feedback regarding the
        Product, Fastinvoice may freely exploit it (including as part of the Product)
        without restriction on account of intellectual property rights or otherwise.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_m6fsu5dfqjl5"
      /><b><span
        lang="EN"
        style="color:black"
      /></b></h3>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_8i36gg11y7pm"
      /><b><span
        lang="EN"
        style="color:black"
      >16. Termination
        and Suspension.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > These Terms will continue until
        terminated.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >1.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Termination by Customer. You
        may terminate these Terms at any time by ceasing all use of the Product and
        deleting any Software and then emailing a clear notice of termination to <span
          style="color:#324BFF"
        >legal@Fastinvoice.com</span>, which must be sent from the
        email address you previously have provided to us, if any.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >2.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Termination or Suspension by
        Fastinvoice. Fastinvoice may terminate or suspend these Terms if Customer fails
        to cure any material breach within fifteen (15) days after written notice,
        except that Fastinvoice may terminate or suspend these Terms immediately, in
        our sole discretion, as a result of Customer’s breach of the express
        obligations under Sections 7b. (Restrictions), 12 (Your Responsibilities) or 13
        (Fastinvoice Money) or to prevent harm to the Product or other customers. In
        addition, for Free Trials and Beta Releases, Fastinvoice may terminate or
        suspend these Terms at any time for any or no reason. Further, if Fastinvoice
        ceases to offer the Product, or if Fastinvoice’s right or ability to offer the
        Product is restricted, suspended or terminated (whether pursuant to Laws or by
        App Marketplaces or other third parties), Fastinvoice may immediately terminate
        or suspend these Terms and you will receive a prorated refund of any fees paid
        for the unused portion of your Subscription Term.</span></p>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.5in;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >3.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Effect of
        Termination. Upon termination of these Terms, your right to the Product will
        automatically terminate and you must cease using the Product and delete any
        Software. Following termination, you will have no further access to any
        Customer Data in the Product and Fastinvoice may delete all Customer Data in
        its possession along with any Instant Website or other material at any time
        unless prohibited by applicable law. You will delete all Confidential
        Information (or return it to Fastinvoice) upon any termination of these Terms
        or request by Fastinvoice. The following will survive termination: Section 7b
        (Restrictions), Sections 11f (Aggregate/Anonymous Data) through 11h (Other Data
        Collection and Handling), Section 12 (Your Responsibilities), the disclaimers
        in Sections 13 (Fastinvoice Money) and 14 (Third Party Capital and Lending
        Partners), and Sections 15 (Ownership) through 25 (Apple-Related Terms).  The
        effect of termination of these Terms on your relationship with Third-Party
        Services is a matter to be resolved between you and the Third-Party Services,
        but in any case, you will no longer have access to the Product.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_fg08hem424iq"
      /><b><span
        lang="EN"
        style="color:black"
      >17. Disclaimer of
        Warranties. </span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, THE PRODUCT IS PROVIDED “AS IS” AND THE Fastinvoice PARTIES AND
        THEIR LICENSORS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS
        OF ANY KIND, INCLUDING ANY WARRANTY OF NON-INFRINGEMENT, TITLE, FITNESS FOR A
        PARTICULAR PURPOSE, FUNCTIONALITY OR MERCHANTABILITY, WHETHER EXPRESS, IMPLIED
        OR STATUTORY. </span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >THE PRODUCT IS NOT INTENDED TO
        PROVIDE PROFESSIONAL ACCOUNTING, TAX OR OTHER ADVICE. TO THE MAXIMUM EXTENT
        PERMITTED BY APPLICABLE LAW: (A) THE Fastinvoice PARTIES EXPRESSLY DISCLAIM ANY
        WARRANTY THAT USE OF THE PRODUCT WILL ENSURE YOUR COMPLIANCE WITH ANY
        ACCOUNTING STANDARDS OR LEGAL OR REGULATORY OBLIGATIONS OR THAT THE RESULTS OF
        THE PRODUCT WILL BE ACCURATE OR SUFFICIENT FOR YOUR PURPOSES, (B) THERE IS NO
        WARRANTY THAT THE PRODUCT WILL BE ERROR FREE, THAT ACCESS WILL BE CONTINUOUS OR
        UNINTERRUPTED, THAT ANY INFORMATION PROVIDED OR USED WITH THE PRODUCT WILL BE
        SECURE, ACCURATE, COMPLETE OR TIMELY, OR THAT ANY CUSTOMER DATA WILL BE
        PRESERVED OR MAINTAINED WITHOUT LOSS, AND (C) THE Fastinvoice PARTIES SHALL NOT
        BE LIABLE FOR ANY PRODUCT FAILURES OR OTHER PROBLEMS INHERENT IN USE OF THE
        INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE THE REASONABLE
        CONTROL OF Fastinvoice. YOU MAY HAVE OTHER STATUTORY RIGHTS; HOWEVER, THE
        DURATION OF STATUTORILY REQUIRED WARRANTIES, IF ANY, WILL BE LIMITED TO THE
        MAXIMUM EXTENT PERMITTED BY LAW.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_rpg5c818umfc"
      /><b><span
        lang="EN"
        style="color:black"
      /></b></h3>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_xi87x6j7e78r"
      /><b><span
        lang="EN"
        style="color:black"
      >18. Limitation of
        Liabilities.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >1.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Consequential Damages Waiver
        and Liability Cap. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
        EVENT WILL THE Fastinvoice PARTIES OR THEIR LICENSORS BE LIABLE TO YOU OR TO ANY
        THIRD PARTY (I) FOR ANY LOST PROFITS, LOSS OF USE, LOST DATA, INTERRUPTION OF
        BUSINESS, FAILURE OF SECURITY MECHANISMS OR ANY SPECIAL, INDIRECT, INCIDENTAL,
        PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, EVEN IF ADVISED OF THE
        POSSIBILITY OF SUCH LOSS OR DAMAGES OR (II) IN ANY EVENT, FOR AMOUNTS EXCEEDING
        THE GREATER OF (A) THE AMOUNT PAID OR PAYABLE BY CUSTOMER TO Fastinvoice IN THE
        PRIOR SIX (6) MONTH PERIOD OR (B) ONE HUNDRED DOLLARS (US$100). </span></p>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.5in;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >2.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > Nature
        of Limitations. Customer acknowledges that the foregoing limitations are an
        essential element of these Terms and a reasonable allocation of risk between
        the parties, and that in the absence of such limitations the pricing and other
        provisions in these Terms would be substantially different. The waivers and
        limitations in this Section 18 apply regardless of the form of action, whether
        in contract, tort (including negligence), strict liability or otherwise and
        will survive and apply even if any limited remedy in this Agreement fails of
        its essential purpose.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_ano0c4za0mhz"
      /><b><span
        lang="EN"
        style="color:black"
      >19. Operated from
        the United States.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >The Product is controlled and
        operated from facilities in the United States and other locations and may
        involve transmission of data or utilization of third-party services globally.
        Fastinvoice makes no representations that the Product is appropriate or
        available for use outside the United States. Those who access or use the
        Product from other jurisdictions do so at their own volition and all users are
        entirely responsible for compliance with all Laws. </span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_2qkxvv2zvflc"
      /><b><span
        lang="EN"
        style="color:black"
      /></b></h3>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_w7cfw9f16gcj"
      /><b><span
        lang="EN"
        style="color:black"
      >20. Consumer Law.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > Notwithstanding any other provision
        of these Terms, If Customer is a Consumer (as defined in Schedule 2 of the
        Competition and Consumer Act 2010 (Cth) and the corresponding provisions of the
        Australian Consumer Law (New South Wales) (“Australian Consumer Law”)):</span></p>

      <ol
        style="margin-top:0in"
        start="1"
        type="1"
      >
        <li
          class="MsoNormal"
          style="line-height:155%"
        ><span
          lang="EN"
          style="font-size:
     12.0pt;line-height:155%"
        >Sections 17 (Disclaimer of Warranties) and 18
          (Limitation of Liabilities) do not apply to any liability of Fastinvoice
          to comply with an applicable consumer guarantee under the Australian
          Consumer Law.</span></li>
        <li
          class="MsoNormal"
          style="line-height:155%"
        ><span
          lang="EN"
          style="font-size:
     12.0pt;line-height:155%"
        >Subject to part (c) below, the liability of
          Fastinvoice for any liability, loss, cost or damage, however caused
          (including by the negligence of Fastinvoice), suffered or incurred by
          Customer because of a failure to comply with a consumer guarantee is
          limited to Fastinvoice (at its election):</span></li>
        <ol
          style="margin-top:0in"
          start="1"
          type="1"
        >
          <li
            class="MsoNormal"
            style="line-height:155%"
          ><span
            lang="EN"
            style="font-size:
      12.0pt;line-height:155%"
          >resupplying the services; or</span></li>
          <li
            class="MsoNormal"
            style="line-height:155%"
          ><span
            lang="EN"
            style="font-size:
      12.0pt;line-height:155%"
          >paying the cost of having the services supplied
            again; and</span></li>
        </ol>
        <li
          class="MsoNormal"
          style="margin-bottom:12.0pt;line-height:155%"
        ><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%"
        >Part (b) does not apply
          if it is not fair or reasonable for  Fastinvoice to rely on it for the
          purposes of section 64A of the Australian Consumer Law.</span></li>
      </ol>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_lbtqyovr1k12"
      /><b><span
        lang="EN"
        style="color:black"
      >21. Confidential
        Information.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > In connection with access to the
        Product, Customer may receive access to confidential or proprietary information
        of Fastinvoice (“Confidential Information”). Confidential Information includes
        the Software, all non-public elements of the Product and any performance
        information regarding the Product. Confidential Information excludes
        information that Customer already lawfully knew or that becomes public through
        no fault of Customer. Customer will (a) use a reasonable degree of care to
        protect all Confidential Information, (b) not use Confidential Information
        except in support of its authorized use of the Product and (c) not disclose
        Confidential Information except to employees and agents with a legitimate need
        to know and who have agreed in writing to keep it confidential. Customer may
        also disclose Confidential Information to the extent required by law after
        reasonable notice to Fastinvoice and cooperation to obtain confidential
        treatment. Unauthorized disclosure of Confidential Information may cause harm
        not compensable by damages, and Fastinvoice may seek injunctive or equitable
        relief in a court of competent jurisdiction, without posting a bond, to protect
        its Confidential Information.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_ouqt3g1y9ts8"
      /><b><span
        lang="EN"
        style="color:black"
      /></b></h3>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_rbunb1loodlg"
      /><b><span
        lang="EN"
        style="color:black"
      >22. Modifications
        to Terms.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > Fastinvoice may modify these Terms
        (including Product pricing and plans) from time to time, with notice given to
        you by email or through the Product. If you have a Free Trial or are using Beta
        Releases, then any modifications will become effective immediately, and if you
        disagree with the modifications, your exclusive remedy is to terminate these
        Terms. You must notify Fastinvoice within fifteen (15) days of notice of the
        modifications that you do not agree to such changes (or thirty (30) days with
        respect to changes to Section 23), and Fastinvoice (at its option and as your
        exclusive remedy) may either: (i) permit you to continue under the prior
        version of these Terms until your next subscription expiration or renewal date
        (after which the modified Terms will apply) or (ii) allow you to terminate
        these Terms and receive a prorated refund of any fees paid to Fastinvoice for
        the unused portion of Fastinvoice fees for your Subscription Term, not
        including any fees for Third-Party Services, for which the effect of
        termination is between you and such Third Party Services. Upon any changes to
        these Terms, you may be required to click to agree to the modified Terms in
        order to continue using the Product, and in any event your failure to terminate
        the Terms pursuant to Section 16a. before the modifications take effect
        constitutes your acceptance of the modifications. As an exception to these
        rules regarding changes to these Terms, (x) if we make changes to the </span><span
        lang="EN"
      ><a href="https://invoice.2go.com/billingpolicy/"><span
        style="font-size:12.0pt;line-height:155%;color:#324BFF;text-decoration:none"
      >Billing
        Policy</span></a></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >,
        the version of the </span><span lang="EN"><a
        href="https://invoice.2go.com/billingpolicy/"
      ><span
        style="font-size:12.0pt;
line-height:155%;color:#324BFF;text-decoration:none"
      >Billing Policy</span></a></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > in effect at the time of
        your most recent purchase (or renewal) shall apply to that usage plan, and (y)
        any changes to our </span><span lang="EN"><a
        href="https://invoice.2go.com/privacyinline/"
      ><span
        style="font-size:12.0pt;
line-height:155%;color:#324BFF;text-decoration:none"
      >Privacy Notice</span></a></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > will automatically become
        effective upon posting as described in the Privacy Notice.</span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_64qx95j541sd"
      /><b><span
        lang="EN"
        style="color:black"
      /></b></h3>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_7cbj4rsx8pq7"
      /><b><span
        lang="EN"
        style="color:black"
      >23. Governing
        Law, Arbitration, and Class Action/Jury Trial Waiver</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >1.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Governing Law. These Terms
        will be construed in accordance with the laws of the State of California, USA
        without reference to its choice of law provisions and without regard to the
        United Nations Convention on Contracts for the International Sale of Goods. For
        any dispute not subject to arbitration, the Federal and State courts located in
        the County of Santa Clara, California will be the exclusive venue for any claim
        or dispute between the parties and the parties hereby consent to the personal
        jurisdiction of those courts for such purposes. The prevailing party in any
        dispute under these Terms will be entitled to its costs and reasonable
        attorneys’ fees.</span></p>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >2.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Arbitration.
        READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE THEIR
        DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM Fastinvoice. </span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >You and Fastinvoice and its
        affiliates agree that these Terms affect interstate commerce and that the
        Federal Arbitration Act governs the interpretation and enforcement of these
        arbitration provisions. This Section 23 is intended to be interpreted broadly
        and governs any and all disputes between us including but not limited to claims
        arising out of or relating to any aspect of the relationship between us,
        whether based in contract, tort, statute, fraud, misrepresentation or any other
        legal theory; claims that arose before these Terms or any prior agreement
        (including, but not limited to, claims related to advertising); and claims that
        may arise after the termination of these Terms (collectively, “Claims”). The
        only disputes excluded from this broad prohibition are the litigation of
        certain claims regarding intellectual property rights and small claims court
        claims, as provided below.</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >For any dispute with Fastinvoice or
        its affiliates, you agree to first contact us at legal@Fastinvoice.com and attempt
        to resolve the dispute with us informally.  With the exception of claims
        regarding intellectual property rights, the parties agree to use their best
        efforts to settle any dispute, claim, question, or disagreement directly
        through consultation, and a reasonable attempt to conduct good-faith
        negotiations shall be a condition to either party initiating a lawsuit or
        arbitration.</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >In the unlikely event that
        Fastinvoice and its affiliates have not been able to resolve a dispute with you
        after sixty (60) days, we each agree to resolve any Claims by binding
        arbitration by JAMS, under the Streamlined Arbitration Rules and Procedures,
        excluding any rules or procedures governing or permitting class or
        representative actions. JAMS may be contacted at </span><span lang="EN"><a
        href="http://www.jamsadr.com/"
      ><span
        style="font-size:12.0pt;line-height:155%;
color:#324BFF;text-decoration:none"
      >www.jamsadr.com</span></a></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >. </span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >If your use of the Product as
        relevant to the dispute was for commercial purposes, (i) the arbitration will
        be conducted in Santa Clara County, California, unless you and Fastinvoice
        agree otherwise, and (ii) each party will be responsible for paying any JAMS
        filing, administrative and arbitrator fees in accordance with JAMS rules,  and
        (iii) each party will also be responsible for their own attorneys’ fees.</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >This paragraph applies only if your
        use of the Product as relevant to the dispute was only for non-commercial
        purposes. If you are an individual located in the United States, the
        arbitration will be conducted (x) at a location determined pursuant to the
        Applicable Rules (provided that such location is reasonably convenient for You
        and does not require travel in excess of 100 miles from Your home); or (y) at
        such other location as may be mutually agreed upon by You and Fastinvoice. If
        you are in individual located outside the United States, the arbitration shall
        be conducted in Santa Clara County, California, and you agree to submit to the
        personal jurisdiction of any federal or state court in Santa Clara, California,
        in order to compel arbitration, to stay proceedings pending arbitration, or to
        confirm, modify, vacate or enter judgment on the award entered by the
        arbitrator. If you commence arbitration in accordance with these Terms, you
        will be required to pay $250 to initiate the arbitration. To the extent the
        filing fee for the arbitration exceeds the cost of filing a lawsuit, the
        arbitrator may require Fastinvoice to pay the additional cost. You are
        responsible for your own attorneys’ fees unless the arbitration rules and/or
        applicable law provide otherwise. If the arbitrator finds the arbitration to be
        non-frivolous, Fastinvoice will pay all of the actual filing and arbitrator
        fees for the arbitration, provided your claim does not exceed $75,000. For
        claims above $75,000, fees and costs will be determined in accordance with
        applicable JAMS rules. The arbitration rules permit you to recover attorney’s fees
        in certain cases.</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Except as set forth in Section 23,
        the arbitrator, and not any federal, state or local court or agency, shall have
        exclusive authority to resolve all disputes arising out of or relating to the
        interpretation, applicability, enforceability or formation of these Terms,
        including, but not limited to any claim that all or any part of these Terms are
        void or voidable, whether a claim is subject to arbitration, and any dispute
        regarding the payment of JAMS administrative or arbitrator fees (including the
        timing of such payments and remedies for nonpayment). The arbitrator shall be
        empowered to grant whatever relief would be available in a court under law or
        in equity. The parties agree that the arbitrator may allow the filing of
        dispositive motions if they are likely to efficiently resolve or narrow issues
        in dispute. The arbitrator’s award shall be written, and binding on the parties
        and may be entered as a judgment in any court of competent jurisdiction.  No
        arbitration award or decision will have any preclusive effect as to issues or
        claims in any dispute with anyone who is not a named party to the arbitration.</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Any arbitration demand or
        counterclaim asserted by either party must contain sufficient information to
        provide fair notice to the other party of the asserting party’s identity, the
        claims being asserted, and the factual allegations on which they are based. The
        arbitrator and/or JAMS may require amendment of any demand or counterclaim that
        does not satisfy these requirements.  The arbitrator has the right to impose
        sanctions in accordance with JAMS Rule 24 for any claims the arbitrator
        determines to be frivolous or improper (under the standard set forth in Federal
        Rule of Civil Procedure 11).</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >The parties agree that JAMS has
        discretion to modify the amount or timing of any administrative or arbitration
        fees due under JAMS’ Rules where it deems appropriate, provided that such
        modification does not increase the costs to you, and you waive any objection to
        such fee modification. The parties also agree that a good-faith challenge by
        either party to the fees imposed by JAMS does not constitute a default, waiver,
        or breach of this Section 23 while such challenge remains pending before JAMS,
        the arbitrator, and/or a court of competent jurisdiction.</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >The parties understand that, absent
        this mandatory provision, they would have the right to sue in court and have a
        jury trial. They further understand that, in some instances, the costs of
        arbitration could exceed the costs of litigation and the right to discovery may
        be more limited in arbitration than in court.</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Notwithstanding the parties’ decision
        to resolve all disputes through arbitration, either party may bring an action
        in state or federal court to protect its intellectual property rights
        (“intellectual property rights” means patents, copyrights, moral rights,
        trademarks, and trade secrets, but not privacy or publicity rights). Either
        party may also elect to have disputes or claims resolved in a small claims
        court that are within the scope of that court’s jurisdiction. Either party may
        also seek a declaratory judgment or other equitable relief in a court of
        competent jurisdiction regarding whether a party’s claims are time-barred or
        may be brought in small claims court in your state and county of residence.
        Seeking such relief shall not waive a party’s right to arbitration under this
        agreement.</span></p>

      <ol
        style="margin-top:0in"
        start="1"
        type="1"
      >
        <li
          class="MsoNormal"
          style="line-height:155%"
        ><span
          lang="EN"
          style="font-size:
     12.0pt;line-height:155%"
        >Class Action/Jury Trial Waiver. WITH RESPECT TO
          ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY HAVE OBTAINED OR USED
          THE PRODUCT FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE
          BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
          CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR SIMILAR, COLLECTIVE ACTION,
          PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER
          APPLIES TO ALL METHODS OF DISPUTE RESOLUTION, INCLUDING CLASS ARBITRATION,
          AND YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND Fastinvoice (FOR
          ITSELF AND ON BEHALF OF ITS AFFILIATES) ARE EACH WAIVING THE RIGHT TO A
          TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION,
          PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY
          KIND TO THE EXTENT PERMITTED BY APPLICABLE LAW. IF THERE IS A FINAL
          JUDICIAL DETERMINATION THAT APPLICABLE LAW PRECLUDES ENFORCEMENT OF THIS
          PARAGRAPH’S LIMITATIONS AS TO A PARTICULAR REMEDY, THEN THAT REMEDY (AND
          ONLY THAT REMEDY) MUST BE SEVERED FROM THE ARBITRATION AND MAY BE SOUGHT
          IN COURT. THE PARTIES AGREE, HOWEVER, THAT ANY ADJUDICATION OF REMEDIES
          NOT SUBJECT TO ARBITRATION SHALL BE STAYED PENDING THE OUTCOME OF ANY
          ARBITRABLE CLAIMS AND REMEDIES.</span></li>
        <li
          class="MsoNormal"
          style="margin-bottom:12.0pt;line-height:155%"
        ><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%"
        >30-Day Right to Opt
          Out.  This paragraph applies only if you are an individual using the
          Product only for non-commercial purposes.  Subject to the rest of this
          paragraph, you have the right to opt-out and not be bound by the
          arbitration and class action waiver provisions set forth above by sending
          written notice of your decision to opt-out to the following address:
          Fastinvoice, 6220 America Center Drive, Suite 100, San Jose, CA 95002.
          Email: legal@Fastinvoice.com. The notice must be sent within 30 days of
          the effective date of these Terms or your first use of the Product,
          whichever is later, otherwise you shall be bound to arbitrate disputes in
          accordance with the terms of those paragraphs. If you opt out of these
          arbitration provisions, Fastinvoice also will not be bound by them.  </span></li>
      </ol>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Fastinvoice will provide 30 days’
        notice of any changes to this Section 23, and changes will become effective on
        the 30th day.  When the updated version of this Section 23 takes effect, any
        unfiled claims of which Fastinvoice does not have actual notice are subject to
        the revised clause.   </span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_7iiwrgs766cl"
      /><b><span
        lang="EN"
        style="color:black"
      /></b></h3>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_gl2521ht1w1v"
      /><b><span
        lang="EN"
        style="color:black"
      >24. General.</span></b></h3>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >1.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Contract Mechanics and
        Interpretation. These Terms constitute the entire agreement between you and
        Fastinvoice and any of its affiliates related to the Product and supersede any
        prior or contemporaneous agreements relating to the Product. If any provision
        of these Terms is held unenforceable, that provision will be enforced to the
        extent permissible by law and the remaining provisions will remain in full
        force. No provision of these Terms will be deemed waived unless the waiver is
        in writing and signed by Fastinvoice. Except as set forth in Section 22
        (Modifications to Terms), all amendments to these Terms must be in writing and
        signed by both parties. In these Terms headings are for convenience only and
        “including” and similar terms will be construed without limitation.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >2.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Assignment; Fastinvoice
        Affiliates. You may not assign or transfer these Terms or any rights hereunder,
        and any attempted assignment or transfer in violation of the foregoing will be
        void.   Fastinvoice may freely assign or transfer its rights or obligations
        hereunder at its sole discretion. These Terms will bind and inure to the
        benefit of each party’s permitted successors and assigns. Fastinvoice may
        permit its corporate affiliates (including Fastinvoice, Inc, and CIMRID PTY
        LTD) and its agents and contractors to exercise its rights and perform its
        obligations under these Terms. Without limiting the foregoing, all limitations
        of liability and disclaimers in these Terms, including Section 17 (Disclaimer
        of Warranties) and 18 (Limitation of Liabilities), also extend to Fastinvoice’s
        corporate affiliates.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >3.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > Fastinvoice Contact
        Information. If you have any questions, complaints or claims regarding the
        Product, please contact:<br>
        Fastinvoice, LLC<br>
        6220 America Center Drive<br>
        Suite 100<br>
        San Jose, CA 95002<br>
        Email: legal@Fastinvoice.com<br>
        If you are a California resident, in accordance with Cal. Civ. Code §1789.3,
        you may report complaints to the Complaint Assistance Unit of the Division of
        Consumer Services of the California Department of Consumer Affairs by
        contacting them in writing at 1625 North Market Blvd., Suite N 112 Sacramento,
        CA 95834, or by telephone at (800) 952-5210 or (916) 445-1254.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >4.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Notices to You. We may send
        notices to your email on file or through the Product. All notices are effective
        upon delivery.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >5.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Publicity. Fastinvoice may
        use Customer’s name, logo and marks on Fastinvoice’s customer lists and
        marketing materials.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >6.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Export Control. The Product
        is subject to U.S. export control laws and regulations and may be subject to
        foreign export or import laws or regulations. You agree to comply strictly with
        all such laws and regulations and not to use or transfer the Product for any
        use relating to nuclear, chemical, or biological weapons or missile technology.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >7.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Anti-Money Laundering and
        Know Your Customer Requirements: Where applicable,  to help the U.S. government
        fight the funding of terrorism and money laundering activities, federal and
        state law requires financial institutions, including money services businesses,
        to obtain, verify, and record information that identifies every customer. </span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >1.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >What this means for You. In
        certain cases, when you use the Product, we will ask you for your name,
        address, date of birth, government identification number, and other information
        that will allow us to identify you.  We may also ask to see other identifying
        documents, including but not limited to, your driver’s license and   business
        related documents.  We will let you know if additional information is required.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >2.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Verification Required. We
        reserve the right to refuse to allow any User, End Customer, or any other
        third-party to use or participate in the Product if we are unable to obtain or
        verify information relating to identity or financial condition, such as
        identifying information for an Organization’s representatives or beneficial owners.
        Notwithstanding any steps that we take to verify the information provided to
        us, you represent and warrant that all information You provide is complete,
        truthful, accurate, and up-to-date. </span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >3.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Review of Accounts and
        Payments. You grant to us and we reserve the right to review or place on hold
        any account or any payment, transfer, or deposit requested or initiated,
        through the Product for any reason, including but not limited to credit and
        fraud risk or compliance with applicable laws (such as anti-money, laundering
        regulations, combating terrorist financing laws, and OFAC sanctions),
        conflicting ownership claims and legal orders. This right does not apply to the
        Banking Services, although any agreement with the Bank Partner may grant
        similar rights to the Bank Partner and its service providers. In our sole
        discretion, we may place a hold on a payment, transfer, or deposit for as long
        as reasonably necessary to conduct an appropriate inquiry regarding the
        payment, an account, a User, an Organization or Individual, a Customer, or
        other third party, or any related facts or circumstances. Depending on the
        results of this review, we reserve the right to take any appropriate action,
        including cancelling the payment, reversing the payment, or continuing to hold the
        payment pending instructions from a government agency.  </span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.5in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >4.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Reports. You acknowledge that
        we may make appropriate reports regarding payments made through the Product,
        including but not limited to financial institutions, regulators, tax agencies
        and law enforcement authorities, and we will cooperate with the appropriate
        authorities in any resulting investigation or prosecution, all as required or
        permitted by law. </span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >8.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > No Office of Foreign Assets
        Control (“OFAC”) Sanctions. You represent and warrant that: (a) You are not
        located in a country that is subject to U.S. Government sanctions, including
        Crimea region of Ukraine, Cuba, Iran, North Korea, or Syria, or other U.S.
        Government  embargos, or are a person, group or entity that has been designated
        by the U.S. Government as a Specially Designated National (SDN) or “terrorist
        supporting” country;  (b) You are not listed on any U.S. Government list of
        prohibited or restricted parties; and (c) You will not use any of the
        Fastinvoice Services to facilitate payments, either directly or indirectly, to
        a sanctioned country, person, group or entity.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >9.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Open Source Software. The
        Product may contain or be provided with components licensed under “open source”
        software licenses (“OSS”). We list the OSS </span><span lang="EN"><a
        href="https://invoice.2go.com/opensource/"
      ><span
        style="font-size:12.0pt;
line-height:155%;color:#324BFF;text-decoration:none"
      >here</span></a></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      > and may update the list from
        time to time as our usage of OSS changes. To the extent required by the
        applicable OSS license, the terms of such licenses will apply in lieu of the
        terms of these Terms with respect to such OSS.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:1.0in;text-indent:-.25in;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >10.<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      /></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Government Users. The Product
        includes commercial computer software and commercial computer software
        documentation. If the user or licensee of the Product is an agency, department,
        or other entity of the United States Government, the use, duplication, reproduction,
        release, modification, disclosure, or transfer of the Product or any related
        documentation of any kind, including technical data and manuals, is restricted
        by a license agreement or by these Terms in accordance with Federal Acquisition
        Regulation 12.212 for civilian purposes and Defense Federal Acquisition
        Regulation Supplement 227.7202 for military purposes. The Product was developed
        fully at private expense. All other use is prohibited.</span></p>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:1.0in;text-indent:-.25in;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >11.<span style="font:7.0pt &quot;Times New Roman&quot;" /></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%"
      >Force
        Majeure. Fastinvoice and its affiliates and their respective employees, agents,
        officers, and directors will not be liable to Customer in any way whatsoever
        for any failure or delay in performance of any of its obligations under these
        terms arising out of any event or circumstance beyond the reasonable control of
        Fastinvoice, including a strike, blockade, pandemic, war, act of terrorism,
        riot, Internet or utility failures, refusal of government license or natural
        disaster.<br>
       </span></p>

      <h3 style="margin:0in;line-height:114%;page-break-after:auto"><a
        name="_cv8fat5r4gac"
      /><b><span
        lang="EN"
        style="color:black"
      >25. Apple-Related
        Terms.</span></b></h3>

      <p
        class="MsoNormal"
        style="line-height:155%"
      ><span
        lang="EN"
        style="font-size:
12.0pt;line-height:155%"
      >If you downloaded the Software from Apple’s App Store,
        then the following terms apply: These Terms are between you and Fastinvoice,
        and not with Apple. However, as required by Apple, Apple and its subsidiaries
        will be third party beneficiaries of these Terms and will have the right (and
        will be deemed to have accepted the right) to enforce these Terms against you
        as a third party beneficiary. As described in Section 17 (Disclaimer of
        Warranties), to the maximum extent permitted by law, we do not make any
        warranties about the Software. If the Software is nonetheless deemed not to
        conform to any warranty that may be implied at law, you may notify Apple and
        Apple will refund the purchase price (if any) for the Software to you. To the
        maximum extent permitted by applicable law, Apple will have no other warranty
        obligation with respect to the Software, and, as between Apple and Fastinvoice,
        any other claims, losses, liabilities, damages, costs or expenses attributable
        to a failure of the Software to conform to an Fastinvoice warranty will be
        Fastinvoice’s responsibility. Apple has no obligation whatsoever to furnish any
        maintenance or support services with respect to the Software. As between
        Fastinvoice and Apple, Fastinvoice is solely responsible for the Software and
        for addressing any claims you or any third parties have about the Software or
        your possession or use of the Software, including (i) product liability claims
        specific to the Software, (ii) any claim that the Software fails to conform to
        any applicable legal or regulatory requirement and (iii) claims specific to the
        Software arising under consumer protection or similar legislation. In the event
        of any third party claim that the Software or your possession or use of the
        Software infringes that third party’s intellectual property rights, Apple will
        not be responsible for the investigation, defense, settlement or discharge of
        such claim.</span></p>

      <p class="MsoNormal">
        <span lang="EN">&nbsp;</span>
      </p>
    </div>
    <br><br><br><br>

  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },

  methods: {

    show() {
      this.$refs.termsModal.show()
    },
    close() {
      this.$refs.termsModal.hide()
    },

  },

}
</script>
