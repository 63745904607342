<!-- eslint-disable no-irregular-whitespace -->
<template>
  <b-modal
    id="termsModal"
    ref="termsModal"
    title="Privacy Policy"
    size="xl"
    hide-footer
  >
    <div class="WordSection1">

      <h1
        align="center"
        style="margin-top:0in;text-align:center;line-height:normal;
page-break-after:auto"
      ><a name="_eaaclyu47ecp" /><b><span
        lang="EN"
        style="font-size:23.0pt;color:#212529"
      >Fastinvoice Privacy Notice</span></b></h1>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;text-align:justify"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#212529"
      >&nbsp;</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;text-align:justify"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#212529"
      >Effective as of: January 1, 2023</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;text-align:justify"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#212529"
      >We’re committed to the confidentiality and security of any
        personal information you provide us with.</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;text-align:justify"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#212529"
      >There is nothing more important to us than our relationship with
        you. We’ve committed to the confidentiality and security of any personal
        information you provide us with. </span><span
        lang="EN"
        style="font-size:12.0pt;
line-height:115%;font-family:&quot;Times New Roman&quot;,serif;color:#232426"
      >We believe
        the only good policy is one you can understand without a lawyer by your side,
        so we’ve worked hard to explain—clearly and in plain English—what we do, what
        information we collect, and why, so you can feel confident about using our
        services.</span></p>

      <p
        class="MsoNormal"
        style="text-align:justify;line-height:150%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:150%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >If anything here is unclear, please reach out to us. We
        sincerely hope this helps you better understand our privacy practices, and how
        we operate. Thank you for joining the Fastinvoice community!</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;text-align:justify"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#212529"
      >This privacy policy explains how we protect your information.</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;text-align:justify;line-height:
150%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:150%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >This privacy policy applies to:</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;
font-family:&quot;Times New Roman&quot;,serif;color:#232426"
      >Fastinvoice.me and its
        subdomains (the “Site”);</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;
font-family:&quot;Times New Roman&quot;,serif;color:#232426"
      >our mobile apps that
        incorporate this privacy policy; and</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;
font-family:&quot;Times New Roman&quot;,serif;color:#232426"
      >products and services
        branded with the FastInvoice name.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;
font-family:&quot;Times New Roman&quot;,serif"
      >If an End Customer receives an invoice
        from one of our Customers and then comes to our website or application, or an
        actual or potential End Customer otherwise interacts with the Service, then the
        actual or potential End Customer is also subject to this Privacy Notice.</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >●<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;
font-family:&quot;Times New Roman&quot;,serif"
      >This Privacy Notice also applies to any
        surveys, rewards, promotions, sweepstakes, contests, referrals, or other
        marketing activities conducted by FastInvoice or in connection with the
        Services (collectively “fastInvoice.me Marketing‘). </span></p>

      <p
        class="MsoNormal"
        style="text-align:justify"
      ><span
        lang="EN"
        style="font-size:
12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif"
      >&nbsp;</span></p>

      <p
        class="MsoNormal"
        style="text-align:justify"
      ><span
        lang="EN"
        style="font-size:
12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif"
      >Please remember
        that our Customers are small businesses who may have their own privacy
        practices. This Privacy Notice does not apply to any third party websites or
        services that Fastinvoice does not own or control. </span></p>

      <p
        class="MsoNormal"
        style="text-align:justify"
      ><span
        lang="EN"
        style="font-size:
12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif"
      >&nbsp;</span></p>

      <p
        class="MsoNormal"
        style="text-align:justify"
      ><span
        lang="EN"
        style="font-size:
12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;color:#232426"
      >“Personal
        Information,” means information about an identifiable individual. That’s what this
        policy is about – our collection, protection, use, retention, disclosure and
        other processing of Personal Information and your rights relating to these
        activities Specifically, we collect the following categories of personal
        information: </span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Contact Information (e.g., name, phone number, address, and
        email address);</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Social Insurance Number or Social Security Number, and other
        government identification numbers (e.g., Driver’s License Number);</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Financial Information (e.g., income, revenue, assets, credits,
        deductions, expenses, and bank account information);</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Date of Birth;</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Payment Data (e.g., checking, debit and credit card account
        numbers, balances and payment history);</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Health Information (e.g., health insurance status and financial
        information related to payment for healthcare services);</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Geo-Location Information;</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Website, Mobile Application, and Email Usage Data (e.g.,
        interactions with a website, application or advertisement);</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Device Information (e.g., internet protocol (IP) address, device
        type, unique identifier, app version, operating system, network data, and phone
        state);</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Login Information; </span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Demographic Information;</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Professional or employment-related information; and</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify;text-indent:-.25in"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;color:#232426"
      >➢<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp; </span></span><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Education information.</span></p>

      <p
        class="MsoNormal"
        style="text-align:justify"
      ><span
        lang="EN"
        style="font-size:
12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;color:#232426"
      >&nbsp;</span></p>

      <p
        class="MsoNormal"
        style="text-align:justify"
      ><span
        lang="EN"
        style="font-size:
12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;color:#232426"
      >&nbsp;</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;text-align:justify;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Fastinvoice will share your information only as described in
        this Privacy Notice, or as permitted by law. Fastinvoice <b> will not sell,
          lease, rent, or trade your personal information to any third party for that
          party’s marketing or promotional purposes, unless you give your consent.</b></span></p>

      <p
        class="MsoNormal"
        style="text-align:justify"
      ><b><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >We also compile certain aggregate or anonymized data about our
        users. What is aggregate data? “Jon Doylw buys a coffee at a coffee shop on
        Bridgeport Street” is the Personal Information of Jon Doyle, but “30% of
        Fastinvoice users buy coffee on Saturdays” is aggregate data. We may use and
        disclose aggregated or anonymized information in our public statements and to
        identify individuals that may be interested in our Services.</span></b></p>

      <p
        class="MsoNormal"
        style="text-align:justify"
      ><b><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >&nbsp;</span></b></p>

      <p
        class="MsoNormal"
        style="text-align:justify"
      ><span
        lang="EN"
        style="font-size:
12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;color:#232426"
      >By
        continuing to use the Service or participating in  Fastinvoice Marketing after
        being provided with this Privacy Notice, you consent to Fastinvoice’s policies
        and practices as described herein.<b> If you do not agree with this Privacy
          Notice, please do not access our Services or otherwise interact with our
          business.</b></span></p>

      <p
        class="MsoNormal"
        style="text-align:justify"
      ><b><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >&nbsp;</span></b></p>

      <ol
        style="margin-top:0in"
        start="1"
        type="1"
      >
        <li
          class="MsoNormal"
          style="color:#232426;text-align:justify"
        ><b><span
          lang="EN"
          style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif"
        >Sources
          and Types of Information We Collect About You<br>
        </span></b><span
          lang="EN"
          style="font-size:12.0pt;line-height:115%;
     font-family:&quot;Times New Roman&quot;,serif"
        >We collect your personal information
          from you directly, through your use of the Fastinvoice Services and/or
          participation in Fastinvoice Marketing, and from third
          parties.Identification information. <br>
          When you sign up for an Fastinvoice account or otherwise correspond with
          us, we collect contact and identifying information, including but not
          limited to your first and last name, phone number, e-mail address, billing
          address, tax identification number or social insurance number
          (collectively, “TIN”),  date of birth (natural persons), government-issued
          identification (natural persons), and business information, including
          company name and address, formation documents, business license, tax
          documents, phone number and business email address. For service
          improvement, security, dispute resolution and training purposes we may
          monitor and record your communications with us (including email and
          telephone) in accordance with applicable laws. <b> <br>
            <i>Information from imported contacts.</i> </b> If you choose to import
          contacts from your email account, including to invite those contacts to
          use the Services, we will collect the username and password for the linked
          email account, as well as the contact information for your contacts. We
          will only use this information for the purposes for which it is shared by
          you.</span></li>
      </ol>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;line-height:155%"
      ><b><i><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Promotions, Surveys and Feedback</span></i></b><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >. We collect information about you when you choose to
        participate in surveys, rewards, promotions, sweepstakes, contests, events,
        referrals, or other Fastinvoice Marketing or when you provide feedback about
        Fastinvoice Services. <br>
        <b><i>Information about your customers.</i></b> We may collect information from
        you about your customers (“End Customers”), including but not limited to name,
        address, email address, phone number, TIN, and financial information, based on
        your use of the Service and payment instructions. <br>
        We may collect the following types of information from your use of the
        Fastinvoice Service or website:<br>
        <b><i>Device and Service Use Information. </i></b> Anytime you use the Services
        (even if you are not registered for our Services), we may collect information
        about how you access or use the Services or input data. This information may
        include unique device identifiers, IP addresses, device or browser type,
        operating system, and device or browser preferences. For additional
        information about how we collect and use this information, see Section 2 (Our
        Use of Cookies and Other Third Party Technologies). <br>
        <b><i>Inferences. </i></b>We may derive inferences about you from the
        information we have collected (e.g. we may infer your geographic location from
        the IP address of device).<br>
        We may collect the following types of information from service providers or
        other third parties: <br>
        <b><i>Information collected from social media. </i></b>We may receive
        information about you when you interact with our Services through various
        social media, for example, by logging in through or “liking” us on Facebook or
        “following” us on Twitter. The types of information  we receive depends  on
        your privacy settings with the particular social network. You should always
        review, and, if necessary, adjust your privacy settings on third party websites,
        mobile applications and services before linking or connecting them to our
        Services. <br>
        <b><i>Identify verification.</i></b> We collect information about you from
        third parties as required and permitted by law, including but not limited to
        the purposes of verifying your identity and/or complying with anti-money
        laundering and anti-terrorism financing laws, “know your customer” regulations,
        and OFAC sanctions requirements. <br>
        <b><i>Compliance and fraud.</i></b> We collect information about you from third
        parties for purposes of fraud detection and prevention, in connection with
        certain types of investigations, or as otherwise permitted by law to ensure the
        safety and security of the Service. <br>
        <b><i>Address and organization information.</i></b> We collect information
        about you or your business from commercially available sources to ensure your
        account information, for example your address, is accurate and up-to-date.</span></p>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;line-height:155%"
      ><b><i><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >Marketing. </span></i></b><span
        lang="EN"
        style="font-size:12.0pt;
line-height:155%;font-family:&quot;Times New Roman&quot;,serif;color:#232426"
      >We collect
        information about current and prospective users of the Services from third
        party services, including but not limited to lead generation services and
        providers of customer and lead data, as well as enriched data about visitors to
        our website, all as permitted by law.</span></p>

      <ol
        style="margin-top:0in"
        start="2"
        type="1"
      >
        <li
          class="MsoNormal"
          style="color:#232426;text-align:justify"
        ><b><span
          lang="EN"
          style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif"
        >Our
          Use of Cookies and Other Third Party Technologies<br>
        </span></b><span
          lang="EN"
          style="font-size:12.0pt;line-height:115%;
     font-family:&quot;Times New Roman&quot;,serif"
        >Fastinvoice and our authorized
          third-party service providers may use cookies and similar third party
          tracking technologies to collect certain types of personal information
          when you use our Services, visit our website, or visit websites that offer
          our Services. The information collected with these technologies helps us
          personalize your experience, enable content, measure the effect of our
          ads, prevent fraud, and enhance the security of our website and the
          Services. You can disable or decline some, but not all cookies, because
          our website and Services rely on certain cookies to function properly.
          Some web browsers have an optional setting called “Do Not Track” (DNT)
          that allows  you to opt-out of being tracked by advertisers and some third
          parties. Because many features of our website and Services cannot function
          without tracking data, we do not currently respond to global DNT
          settings.Our use of cookies and other tracking technologies is discussed
          in more <b>detail in our Cookie Statement. </b></span></li>
        <li
          class="MsoNormal"
          style="color:#232426;text-align:justify"
        ><b><span
          lang="EN"
          style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif"
        >How
          We Use the Information We Collect About You<br>
        </span></b><span
          lang="EN"
          style="font-size:12.0pt;line-height:115%;
     font-family:&quot;Times New Roman&quot;,serif"
        >To register your account.  When you
          create an Fastinvoice account or enroll as an Fastinvoice account user, we
          use the information that we collect about you, including any identifying
          information or financial information, for purposes related to the review,
          establishment, provision, administration, maintenance, and monitoring of
          your Fastinvoice account or your use of the Services. We also use this
          information to verify your identity and your eligibility to use the
          Services, and to comply with applicable law.<br>
          To provide the Fastinvoice service. We use the information that we collect
          about you, together with information that you provide about your End
          Customers and the content that you upload to the Services to provide the
          Services to you in accordance with your instructions and Fastinvoice
          account settings and choices.  We also use your information to process
          payments requested by you and to provide you with transaction history and
          account information. We also use your information to provide, maintain and
          improve the Services to you and your actual and potential End Customers. <br>
          To provide customer support. We use your information to process and
          respond to your requests or inquiries, and to provide you with customer
          service, technical support, or software updates.<br>
          To diagnose or fix technology problems.  We use your information to secure
          and protect the integrity of our network, help diagnose and troubleshoot
          potential hardware malfunctions, and otherwise ensure proper functioning
          of our Service.</span></li>
      </ol>

      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:.5in;text-align:justify;line-height:155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >To detect fraud. We use your information to detect and control
        unauthorized use or abuse of our  Services and our other products and services,
        or otherwise detect, investigate or prevent activities that may violate our
        policies or be illegal.<br>
        To defend and/or prosecute legal claims.  We may use your information to
        prosecute and/or defend a court, arbitration or similar legal proceeding, or to
        respond to lawful requests by public authorities (including national security
        or law enforcement requirements).<br>
        For research and development.  We may use your information to analyze trends,
        administer or optimize the Services, monitor usage or traffic patterns
        (including to better understand how you use the Services), develop new
        products, features and technologies that benefit our customers and the public.<br>
        To communicate with you about the Services.  We use your information to
        communicate directly with you, including by e-mail, SMS, push notification or
        phone, to send you transactional communications, respond to your questions,
        requests or comments, provide customer support, provide technical notices,
        updates, security alerts, and administrative messages.  We also use your
        information to  send you newsletters, promotions and special offers or
        information about new products and services in accordance with your marketing
        preferences. Your opt-out options for promotional communications are described
        below under Section 5 (Your Choice about your information). <br>
        To serve ads.  We may use your information to  deliver advertising to you,
        including to help advertisers and publishers serve and manage ads on the
        Service or on third party sites, and to tailor ads based on your interests and
        browsing history (please see Section 2 (Our Use of Cookies and Other Third
        Party Technologies) and Section 5 below (Your Choices about Your Information
        for additional information related to such advertising and your related
        controls).<br>
        With your consent.  We use information about you where you have given us
        consent to do so for a specific purpose not listed above.  As one example, we
        may publish testimonials or featured customer stories to promote the Services,
        with your permission.<br>
        Other legitimate business purposes. We may use the information that we collect
        about you as required or permitted by law. Such uses include but are not
        limited to compliance with anti-money laundering and anti-terrorism financing
        laws, “know your customer” regulations, and OFAC sanctions requirements.</span></p>

      <ol
        style="margin-top:0in"
        start="4"
        type="1"
      >
        <li
          class="MsoNormal"
          style="color:#232426;text-align:justify"
        ><b><span
          lang="EN"
          style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif"
        >How
          We Share the Information We Collect About You<br>
        </span></b><span
          lang="EN"
          style="font-size:12.0pt;line-height:115%;
     font-family:&quot;Times New Roman&quot;,serif"
        >With our service providers.  We may
          share your information with our service providers that perform services on
          our behalf, as needed to carry out their work for us, which may include
          providers of services for identifying and serving targeted advertisements,
          content or service fulfillment, or providing analytic, archival, auditing,
          accounting, legal, business consulting, banking, payment, delivery, data
          processing, data analysis, research, investigation, marketing, website or
          other technology services;<br>
          With your authorized third parties. At your direction, we may share your
          information with your third party vendors, service providers or agents.<br>
          With our Customers for whom you are their actual or potential End Customer
          in the context of the Service. For example, as an End Customer, we may
          share certain information about you with the Customer, such as whether you
          have opened an invoice or made a payment.<br>
          With our other partners.  We may share your information with our Capital
          or Lending Partners (described in Section 14 of our Terms of Service), who
          may use it to provide you with opportunities for Capital or Loans in
          accordance with their own separate agreement or terms with you (which may
          be displayed through the Service or separately.<br>
          In connection with corporate transactions.   We may share your information
          with other parties in connection with a company transaction, such as a
          merger, sale of company assets or shares, reorganization, financing,
          change of control or acquisition of all or a portion of our business by
          another company or third party or in the event of a bankruptcy or related
          or similar proceedings.  <br>
          For compliance and harm prevention. We may share your information if we
          have a good faith belief that disclosure of such information is reasonably
          necessary to: (i) satisfy any law, legal process, or governmental request;
          (ii) enforce our Terms of</span><span
          lang="EN"
          style="font-size:12.0pt;
     line-height:115%;font-family:&quot;Times New Roman&quot;,serif;color:windowtext"
        >
          Service</span><span
          lang="EN"
          style="font-size:12.0pt;line-height:115%;
     font-family:&quot;Times New Roman&quot;,serif"
        >, including investigation of
          potential violations thereof; or (iii) protect against fraud, imminent
          harm to the Service or the rights, property or safety of Fastinvoice, our
          users or the public as required or permitted by law. <br>
          Any other person, with your consent to the disclosure.<br>
          Aggregated and Anonymized Data.  We may share aggregated or anonymized
          data with third parties for other purposes. Such information is not
          Personal Information as it does not identify you individually, but may
          include usage, viewing and technical information we collected through our
          technology. If we are required under any applicable law to treat such
          information as Personal Information, then we will only disclose it as
          described above. Otherwise we may disclose such information for any
          reason. </span></li>
        <li
          class="MsoNormal"
          style="color:#232426;text-align:justify;line-height:155%"
        ><b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif"
        >Your
          Choices and Privacy Rights<br>
        </span></b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;
     font-family:&quot;Times New Roman&quot;,serif"
        >End Customers should contact the
          relevant Customer with any requests regarding their information.<br>
          Managing your account. When you sign up for a Fastinvoice account, you
          will create an account profile. You have options for managing your
          account information and communication preferences within your account.
          You  can  update certain account information and communication preferences
          at any time by logging into your account and changing your account
          settings. If you have any questions about reviewing or modifying your
          account information, you can contact us directly at the address provided
          in Section 12 (How to Contact Us).   <br>
          Managing marketing communications.  We will honor your request to opt out
          from receiving marketing communications from us. You  can unsubscribe from
          promotional emails by clicking on the “unsubscribe link” provided in such
          communications. You can also stop receiving communications from us by
          emailing us an opt-out request at <span style="background:yellow">support@fastinvoice.me</span>
          .<br>
          We make every effort to promptly process all unsubscribe requests. You may
          not, however, opt out of Service-related communications (e.g., account
          verification, purchase and billing confirmations and reminders,
          changes/updates to features of the Service, technical and security
          notices) unless you terminate your account. Residents of some states and
          countries have additional privacy rights, which you can review in the
          relevant Supplemental Privacy Notices below. </span></li>
        <li
          class="MsoNormal"
          style="color:#232426;text-align:justify;line-height:155%"
        ><b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif"
        >How
          Long We Keep the Information We Collect About You<br>
        </span></b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;
     font-family:&quot;Times New Roman&quot;,serif"
        >Retention periods can vary
          significantly based on the type of information we collect and how it is
          used. Fastinvoice’s retention periods are based on criteria that include
          our ability to provide you with the Service or administer your Fastinvoice
          account, legally mandated retention periods, pending or potential
          litigation, our intellectual property or ownership rights, contract
          requirements, operational directives or needs, and historical archiving.</span></li>
        <li
          class="MsoNormal"
          style="color:#232426;text-align:justify;line-height:155%"
        ><b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif"
        >How
          We Store and Protect the Information We Collect About You<br>
        </span></b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;
     font-family:&quot;Times New Roman&quot;,serif"
        >Fastinvoice uses commercially
          reasonable physical, technical, and organizational measures to help
          protect the confidentiality, integrity and security of all information
          collected through the Services. However, no security system is
          impenetrable and we cannot guarantee the security of our systems 100%. In
          the event that any information under our control is compromised as a
          result of a breach of security, Fastinvoice will take reasonable steps to
          investigate the situation and where appropriate, notify those individuals
          or customers whose information may have been compromised and take other
          steps, in accordance with any applicable laws and regulations.<br>
          Password Security: Your  passwords are an important element of our
          security scheme, and as such, you are responsible for maintaining the
          confidentiality of your password and account, and are fully responsible
          for all activities that occur under the password or account. You must
          report any misuse or compromise of your password to Fastinvoice at the
          contact address provided in Section 11 (How to Contact Us) as soon as they
          become aware of any such misuse or compromise.</span></li>
        <li
          class="MsoNormal"
          style="color:#232426;text-align:justify;line-height:155%"
        ><b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif"
        >International
          Data Transfers<br>
        </span></b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;
     font-family:&quot;Times New Roman&quot;,serif"
        >Your personal information may be
          transferred to countries located outside of your country or region,
          including to countries that may not provide a similar or adequate level of
          protection to that provided by your country or region. For example, if you
          are located in the European Economic Area (“EEA”), Canada, New Zealand, or
          Australia, we may transfer your personal information to the United States
          or other countries outside of the country of your residence. By using our
          Service or website or otherwise providing personal information to us, you
          hereby expressly consent to the transfer of your personal information
          outside your country or region.<br>
          For the transfer of personal information from the EEA to any countries not
          recognized by the European Commission as providing an adequate level of
          data protection according to EEA standards, we have implemented adequate
          measures to protect the personal information, such as by ensuring the
          recipients are bound by standard contractual clauses adopted by the
          European Commission.</span></li>
        <li
          class="MsoNormal"
          style="color:#232426;text-align:justify;line-height:155%"
        ><b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif"
        >Children’s
          privacy<br>
        </span></b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;
     font-family:&quot;Times New Roman&quot;,serif"
        >Fastinvoice does not knowingly
          collect or solicit any information from anyone under the age of 18 or
          knowingly allow such persons to sign up for an Fastinvoice account. In the
          event that we learn that we have collected Personal Information from a
          child under age 18, we will delete that information where required by law.
          If you believe that we might have any Personal Information from a child
          under age 18, please contact us at the address provided in Section 11 (How
          to Contact Us).</span></li>
        <li
          class="MsoNormal"
          style="color:#232426;text-align:justify;line-height:155%"
        ><b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif"
        >Links
          to other web sites and services<br>
        </span></b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;
     font-family:&quot;Times New Roman&quot;,serif"
        >Our Services may integrate with or
          contain links to other third party websites and services. We are not
          responsible for the practices employed by third party websites or services
          embedded in, linked to, or linked from the Services. Your interactions
          with any third party website or service are subject to that third party’s
          own rules and policies. You are responsible for examining third party
          websites’ privacy policies and for making your own decisions regarding the
          accuracy, reliability and correctness of material and information found.</span></li>
        <li
          class="MsoNormal"
          style="color:#232426;margin-bottom:12.0pt;text-align:justify;
     line-height:155%"
        ><b><span
          lang="EN"
          style="font-size:12.0pt;line-height:
     155%;font-family:&quot;Times New Roman&quot;,serif"
        > How to Contact Us<br>
        </span></b><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;
     font-family:&quot;Times New Roman&quot;,serif"
        >If you have any questions or otherwise
          need to contact us in connection with this Privacy </span><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif;
     color:windowtext"
        >Notice or the Service, you can reach our customer
          support portal or our Privacy Team at <span style="background:yellow">support@fastinvoice.me</span></span><span
          lang="EN"
          style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif"
        >
          or  contact us at:</span></li>
      </ol>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;text-align:justify;line-height:
155%"
      ><b><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:
&quot;Times New Roman&quot;,serif;color:#232426"
      >12. Changes to Our Privacy Notice<br>
      </span></b><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:
&quot;Times New Roman&quot;,serif;color:#232426"
      >This Privacy Notice may be updated periodically
        to reflect any changes in our business practices, services or the relevant
        laws. The Privacy Notice will be posted on our  website, with an “Effective
        Date” legend at the top of the page indicating when it was last updated. Any
        changes are effective on the date that the updated Privacy Notice is posted.If
        the applicable law requires that we provide notice in a specified manner prior
        to making any changes to this Privacy Notice, we will provide such required
        notice. If you object to any changes to this Privacy Notice, you can cancel
        your account at any time.  By continuing to use the Service or participating in
        Fastinvoice Marketing  after being provided with this Privacy Notice, you
        consent to the updated Privacy Notice.</span></p>

      <p
        class="MsoNormal"
        style="margin-bottom:12.0pt;text-align:justify;line-height:
155%"
      ><span
        lang="EN"
        style="font-size:12.0pt;line-height:155%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >&nbsp;</span></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify"
      ><b><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      ><br>
        <br>
      </span></b></p>

      <p
        class="MsoNormal"
        style="margin-left:.5in;text-align:justify"
      ><b><span
        lang="EN"
        style="font-size:12.0pt;line-height:115%;font-family:&quot;Times New Roman&quot;,serif;
color:#232426"
      >&nbsp;</span></b></p>

    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },

  methods: {

    show() {
      this.$refs.termsModal.show()
    },
    close() {
      this.$refs.termsModal.hide()
    },

  },

}
</script>
