<template>
  <b-overlay
    id="overlay-background"
    :show="pageIsLoading"
    variant="light"
    opacity="0.85"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <b-card

        class="text-center"
      >

        <h2>
          <b-spinner

            class="mr-1"
            label="Loading"
          />
          {{ overlayMsg }}
        </h2>

      </b-card>
    </template>
    <div class="auth-wrapper auth-v2">

      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link
          class="brand-logo"
          to="https://fastinvoice.me"
        >
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Fast Invoice
          </h2>
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Register V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Register-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title class="mb-1">
              Adventure starts here 🚀
            </b-card-title>
            <b-card-text class="mb-2">
              Make your app management easy and fun!
            </b-card-text>
            <b-alert
              v-if="success"
              variant="success"
              show
            >
              <h4 class="alert-heading">
                You have successfully registered
              </h4>

            </b-alert>
            <b-alert
              v-if="errorData"
              variant="danger"
              show
            >
              <h4 class="alert-heading">
                {{ errorData.message }}
              </h4>
              <div class="alert-body">
                <div
                  v-for="(v, k) in errorData.errors"
                  :key="k"
                >
                  <span
                    v-for="error in v"
                    :key="error"

                    style="display: inline-block;"
                  ><strong>*</strong>{{ error }}</span>

                </div>

              </div>
            </b-alert>
            <!-- form -->
            <validation-observer
              ref="registerForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="register"
              >

                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="register-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="userEmail"
                      name="register-email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group
                  label-for="register-password"
                  label="Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        name="register-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <vue-recaptcha
                  ref="recaptcha"
                  size="invisible"
                  :sitekey="recaptchaSiteKey"
                  @verify="onCaptchaVerified"
                  @expired="resetCaptcha"
                />
                <b-form-group>
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="privacyAggred"
                    name="checkbox-1"
                  >
                    I agree to
                    <b-link @click="showPrivacyPolicy">
                      privacy policy
                    </b-link>
                    &
                    <b-link @click="showTerms">
                      Terms of Service
                    </b-link>

                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  Sign up
                </b-button>
              </b-form>
            </validation-observer>
            <div class="divider my-2">
              <div class="divider-text">
                or
              </div>
            </div>

            <!-- social button -->
            <div
              v-if="true==false"
              class="d-flex justify-content-center"
            >

              <b-button
                href="javascript:void(0)"
                variant="google"
                block
                @click="socialLogin('google')"
              >
                <feather-icon icon="MailIcon" />
                {{ $t('Sign In With Google') }}
              </b-button>
            </div>
            <p class="text-center mt-2">
              <span>Already have an account?</span>
              <b-link :to="{name:'auth-login'}">
                <span>&nbsp;Sign in instead</span>
              </b-link>
            </p>

          </b-col>
        </b-col>

        <!-- /Register-->
      </b-row>

    </div>
    <terms-modal

      ref="refTermsModal"
    />
    <privacy-policy-modal

      ref="refPrivacyModal"
    />
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BAlert, BOverlay, BSpinner, BCard,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { Providers } from 'universal-social-auth'
import { VueRecaptcha } from 'vue-recaptcha'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import termsModal from './TermsModal.vue'
import privacyPolicyModal from './PrivacyPolicyModal.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    VueRecaptcha,
    BImg,
    BOverlay,
    BSpinner,
    BCol,
    termsModal,
    privacyPolicyModal,
    BLink,
    BButton,
    BAlert,
    BForm,
    BCard,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      recaptchaSiteKey: process.env.VUE_APP_INVISIBLE_RECAPTCHA_SITEKEY,
      pageIsLoading: false,
      overlayMsg: 'Registering...',
      privacyAggred: false,
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      recaptchaToken: '',
      // validation
      required,
      email,
      errorData: null,
      success: null,
      // social auth
      responseData: {},
      hash: '',
      data: {},
      fauth: false,
      // end social auth
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    showTerms() {
      this.$refs.refTermsModal.show()
    },
     showPrivacyPolicy() {
      this.$refs.refPrivacyModal.show()
    },
    // social auth
    socialLogin(authType) {
      this.useAuthProvider(authType, null)
    },

    useAuthProvider(provider, proData) {
      const pro = proData
      const ProData = pro || Providers[provider]
      this.$Oauth.authenticate(provider, ProData).then(response => {
        const rsp = response
        if (rsp.code) {
          this.responseData.code = rsp.code
          this.responseData.provider = provider
          this.useSocialLogin()
        }
      // eslint-disable-next-line no-unused-vars
      }).catch(err => {
        alert('Provider login error please login with username and password')
      })
    },
    useSocialLogin() {
    // otp from input Otp form
    // hash user data in your backend with Cache or save to database
      const pdata = { code: this.responseData.code, otp: this.data.tok, hash: this.hash }
      this.$axios.post(`${process.env.VUE_APP_API_URL}/api/social-login/${this.responseData.provider}`, pdata).then(async response => {
      // `response` data base on your backend config
        if (response.data.status === 444) {
          this.hash = response.data.hash
          this.fauth = true // Option show Otp form incase you using 2fa or any addition security apply to your app you can handle all that from here
        } else if (response.data.status === 445) {
        // do something Optional
        } else {
          this.handleRegisterSuccessMsg(response)
        }
      }).catch(error => {
        this.pageIsLoading = false
        //   console.log(error.response.data.error)
        this.errorData = error.response.data
        this.$refs.loginForm.setErrors(error.response.data.error)
      })
    },
    // eslint-disable-next-line no-unused-vars
    async  useLoginFirst(e) {
    // this sample of to pust user data to my store
      // console.log('userdata', e)
    },
    // social login methods

    handleRegisterSuccessMsg(response) {
      useJwt.setToken(response.data.data.accessToken)
      useJwt.setTokenExpiry(response.data.data.expires_in)
      useJwt.setRefreshToken(response.data.data.refreshToken)
      localStorage.setItem('userData', JSON.stringify(response.data.data.user_data))
      store.commit('app/UPDATE_USER_DATA', response.data.data.user_data)
      this.$ability.update(response.data.data.user_data.ability)
      this.pageIsLoading = false
      this.$router.push({ name: 'setup-welcome' })
    },
    onCaptchaVerified(token) {
      this.resetCaptcha()
      this.recaptchaToken = token
      this.handleRegister()
    },
    handleRegister() {
      if (!this.privacyAggred) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Warning',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'Please confirm the privacy policy and terms of use.',
          },
        })
        return
      }

      this.pageIsLoading = true
      useJwt.register({
        username: this.username,
        email: this.userEmail,
        password: this.password,
        password_confirmation: this.password,
        recaptcha_response: this.recaptchaToken,
      })
        .then(response => {
          this.handleRegisterSuccessMsg(response)
        })
        .catch(error => {
          this.pageIsLoading = false
          this.$refs.registerForm.setErrors(error.response.data.errors)
          if (error.response.status === 422) {
            this.errorData = error.response.data
          }
        })
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset()
    },
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$refs.recaptcha.execute()
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
